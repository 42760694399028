<template>
    <div>
        <page-header v-if="!hasCreatePermission" copyLink="ACTIONS.COPY_LINK_PUBLIC_CLIENT"
                     pageTitle="CLIENTS.INDEX_TITLE" :haveSearch="true"
                     @searching="search($event)"/>
        <page-header v-if="hasCreatePermission" copyLink="ACTIONS.COPY_LINK_PUBLIC_CLIENT"
                     pageTitle="CLIENTS.INDEX_TITLE" :haveSearch="true" :dropdownClient="!widthMd(576)"
                     @uploadFileClient="uploadFile = true; isAnex = false"
                     btnNew="Nova Pessoa Jurídica"
                     secondBtn="Nova Pessoa Física"
                     @newType="setRegisterModal('juridical')"
                     @secondAction="setRegisterModal('phisical')"
                     @copyLink="copyLinkClient" @searching="search($event)"
                     @exportClient="exportClient()"/>

        <list-component :items="items.data" @open="open($event)" :haveDetails="true" v-if="items && !items.validations">
            <template v-slot:principal-info="{items}">
                <div class="list-primary hide-text"><i title="Cadastro incompleto"> {{
                        items.iten.incomplete_register ? '⚠️' : ''
                    }}</i>&nbsp; &nbsp;{{ items.iten?.name ? items.iten?.name : items.iten.corporate_name }}
                </div>
            </template>
            <template v-slot:dropdown-options="items">
                <div id="teste" class="dropdown-item pointer text-white" data-target="#customModalTwo"
                     data-toggle="modal"
                     @click="setEditModal(items.iten.id)" v-if="hasUpdatePermission" data-cy="edit-client">
                    <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                </div>
                <div class="dropdown-item pointer text-white" @click="showAlert(items.iten.id)"
                     v-if="hasDeletePermission"
                     data-cy="destroy-client">
                    <i class="icon-delete mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                </div>
                <div class="dropdown-item pointer text-white" data-target="#customModalTwo" data-toggle="modal"
                     @click="setViewModal(items.iten.id)" v-if="hasShowPermission">
                    <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                </div>
                <!--                <div class="dropdown-item pointer text-white" data-target="#customModalTwo" data-toggle="modal"-->
                <!--                     @click="setSendDocumentModal(items.iten.id)" v-if="hasShowPermission">-->
                <!--                    <i class="icon-attach_file mr-2 font-15"/>{{ t('ACTIONS.ATTACH_DOCUMENT') }}-->
                <!--                </div>-->
                <div class="dropdown-item pointer text-white"
                     @click="printClientPdf(items.iten)" v-if="hasShowPermission">
                    <i class="icon-file mr-2 font-15"/>{{ t('ACTIONS.PRINT_PDF_CLIENT') }}
                </div>
            </template>
            <template v-slot:details-itens="items">
                <div class="form-group mt-2 text-center" v-if="!widthPhone()">
                    <label for="name" class="mb-2">{{ t('GENERAL.PHONE') }}: </label>
                    <p class="color-grey mt-2">{{ phoneNumber(items.iten) }}</p>
                </div>
                <div class="form-group mt-2 text-center" v-if="!widthPhone()">
                    <label for="name" class="mb-2">{{ t('COMPANIES.TAXPAYER') }}: </label>
                    <p class="color-grey mt-2">{{ items.iten.taxpayer }}</p>
                </div>
                <div class="form-group mt-2 text-center" v-if="!widthPhone()">
                    <label for="name" class="mb-2">{{ t('GENERAL.EMAIL') }}: </label>
                    <p class="color-grey mt-2">{{ items.iten.email }}</p>
                </div>
                <div class="form-group mt-2 text-center" v-if="!widthPhone() && hasDeletePermission">
                    <label for="name" class="mb-2">{{ t('GENERAL.ADDED_BY') }}: </label>
                    <p class="color-grey mt-2">{{ realtorName(items.iten) }}</p>
                </div>
                <div class="row gutters" v-if="widthPhone()">
                    <div class="col-sm-6 col-6">
                        <div class="form-group mt-2 ml-2 text-left">
                            <label for="name">{{ t('GENERAL.PHONE') }}: </label>
                            <p class="color-grey mt-2">{{ phoneNumberToCell(items.iten) }}</p>
                        </div>
                        <div class="form-group mt-2 ml-2 text-left">
                            <label for="name">{{ t('COMPANIES.TAXPAYER') }}: </label>
                            <p class="color-grey mt-2">{{ items.iten.taxpayer }}</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6">
                        <div class="form-group mt-2 ml-2 text-left" v-if="widthPhone()">
                            <label for="mail">{{ t('GENERAL.EMAIL') }}: </label>
                            <p id="mail" class="color-grey mt-2">{{ items.iten.email }}</p>
                        </div>
                        <div class="form-group mt-2 ml-2 text-left" v-if="widthPhone() && hasDeletePermission">
                            <label for="addBy" class="mb-2">{{ t('GENERAL.ADDED_BY') }}: </label>
                            <p id="addBy" class="color-grey mt-2">{{ realtorName(items.iten) }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </list-component>
        <div class="card" v-if="items && items.validations">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NONE_CLIENT_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <div class="row gutters w-100">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/cliente'"
                                      @changePage="index(filter, $event)"></pagination-component>
            </div>
            <div class="col-3 w-100" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end w-100" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Pessoas por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <modal :titleModal="modalTitle" :modalXl="true" @close="closeModal">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-client @to-update="switchToEdit" v-if="client && wichModal == 1" :clientIndex="client"
                           :addressIndex="address" @update="index()"
                           :is-phisical-index="isPhisical"
            >
            </create-client>
            <view-client v-if="client && wichModal == 2" :clientIndex="client" :addressIndex="address">
            </view-client>
            <div v-if="wichModal == 3">
                <div class="modal-row2 modal-height-max">
                    <div class="modal-body">
                        <form id="formUpload">
                            <div class="row gutters">
                                <!--                                <div class="col-6">-->
                                <!--                                    <div class="form-group">-->
                                <!--                                        <label for="description"> Descrição: </label>-->
                                <!--                                        <div class="input-group">-->
                                <!--                              <textarea id="description" class="form-control h-75" v-model="fileDescription"-->
                                <!--                                        aria-label="With textarea" style="max-height: 2.5rem"></textarea>-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <!--                                <div class="col-6">-->
                                <!--                                    <div class="form-group">-->
                                <!--                                        <label for="fakeFile" class="required">Arquivo:</label>-->
                                <!--                                        <div class="input-group">-->
                                <!--                                            <div class="input-group-prepend">-->
                                <!--                                                <button class="btn btn-primary" type="button" id="button-addon1"-->
                                <!--                                                        @click="setFile"-->
                                <!--                                                        style="min-height: 2.5rem">-->
                                <!--                                                    Selecionar arquivo-->
                                <!--                                                </button>-->
                                <!--                                            </div>-->
                                <!--                                            <input id="fakeFile" class="form-control" placeholder=""-->
                                <!--                                                   style="min-height: 2.5rem"-->
                                <!--                                                   :value="file.fileName" disabled>-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                    <input id="inputFileDocument" type="file" @change="chooseFile($event)"-->
                                <!--                                           style="display: none" accept=".pdf">-->
                                <!--                                </div>-->
                                <div class="col-12 justify-content-end d-flex mb-3">
                                    <a class="btn btn-custom bg-btn ml-3"
                                       @click="setFile" id="new_anex">
                                        <i class="icon-circle-with-plus"></i> Adicionar
                                    </a>
                                </div>
                            </div>
                            <div class="row gutters" v-if="documents?.length">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row pl-4">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 font-bold">
                                        <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                                this.t('CLIENTS.DOCUMENTS.NAME')
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 font-bold">
                                        <div class="font-weight-bolder mt-2 ml-3 text-left">{{
                                                this.t('CLIENTS.DOCUMENTS.SIZE')
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                        <div class="font-weight-bolder mt-2 ml-4 text-left">
                                            {{ this.t('CLIENTS.DOCUMENTS.ACTIONS') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2"
                                     v-for="(document, key) in documents" :key="key"
                                     :class="key != 0 ? 'border-top2': ''">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                        <div class="list-primary  text-left">
                                            {{ document.name }}
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                        <div class="list-primary  text-left">
                                            {{ formatSize(document.file_size) }}
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 row">
                                        <div class="list-primary pointer text-center" title="Download">
                                            <i class="icon-download pl-3" @click="downloadDocument(document)"></i>
                                        </div>
                                        <div class="list-primary pointer text-center" title="Deletar Anexo">
                                            <i class="icon-trash-2 pl-2" @click="documentDelete(document.id)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card w-100" v-else>
                                <div class="card-header"></div>
                                <div class="card-body pt-0">
                                    <div class="text-center">
                                        <h5>Nenhum Documento Foi Cadastrado</h5>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" @click="closeModalAnex()">
                        {{ t('ACTIONS.CLOSE') }}
                    </button>
                </div>
            </div>
        </modal>
        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event"
                      @import="importFile($event)"></upload-modal>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import Modal from "@/components/modal";
import Clients from '@/services/Clients';
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import viewClient from "./viewClientComponent.vue";
import User from "@/services/user";
import Permission from '@/components/permission.vue';
import createClient from "./createClientComponent.vue";
import pageHeader from "@/components/layouts/pageHeader";
import ListComponent from "@/components/layouts/listComponent.vue";
import PaginationComponent from "@/components/layouts/PaginationComponent";
import jwt_decode from "jwt-decode";
import uploadModal from "@/components/layouts/uploadModal";
import Drive from "../../services/Drive";
import Select2 from 'vue3-select2-component';
import {mapState} from 'vuex';
import widthMd from '../../mixins/widthMd';
import AttibutesValidations from '../../services/AttibutesValidations';
import {PDFDocument} from 'pdf-lib';

export default {
    name: 'indexClient',
    mixins: [Permission, Validate, widthMd],
    components: {
        Modal,
        pageHeader,
        viewClient,
        createClient,
        ListComponent,
        PaginationComponent,
        uploadModal,
        Select2,
    },
    computed: {
        ...mapState(['paginate'])
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            isPhisical: true,
            documents: [],
            items: null,
            client: null,
            file: [],
            fileDescription: null,
            perPage: null,
            clientUploadDocument: null,
            filter: null,
            number: "",
            address: null,
            wichModal: null,
            nameOfCreator: "",
            ids: [],
            uploadFile: false,
            modalTitle: null,
            hasShowPermission: false,
            hasListPermission: false,
            hasCreatePermission: false,
            hasUpdatePermission: false,
            clientPublic: window.location.host,
            hasDeletePermission: false,
            isAnex: null,
        }
    },

    mounted() {
        this.index();
        this.startPermissons();
        this.getNamesOfRealtor();
    },
    methods: {
        closeModal() {
            this.wichModal = null;
        },
        changePaginate() {
            this.index(this.filter, 1);
        },
        async getAttributesValidations(context) {
            this.$store.commit('changeLoading', true);
            await AttibutesValidations.getByContext(context).then(resp => {
                this.$store.commit('setItemsValidations', resp.data);
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        closeModalAnex() {
            document.getElementById('closeX').click();
        },
        documentDelete(id) {
            this.$store.commit('changeLoading', true);
            Drive.fileDelete(id).then(() => {
                this.documents = this.documents.filter(document => {
                    if (document.id !== id) return document;
                })
                this.toast.success(this.t('CLIENTS.DOCUMENTS.DELETE_SUCCESSFULL'));
                this.$store.commit('changeLoading', false);
            }).catch(err => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            });
        },
        formatSize(fileSize) {
            if (fileSize < 1000) return `${fileSize} Bytes`
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`
        },
        downloadDocument(doc) {
            this.$store.commit('changeLoading', true);
            Drive.download(doc.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.name);
                link.click();
                this.$store.commit('changeLoading', false);
            }).catch(() => this.$store.commit('changeLoading', false));
        },
        setSendDocumentModal(id) {
            this.$store.commit('changeLoading', true);
            Clients.show(id).then((resp) => {
                this.documents = resp.data.documents;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.documents = [];
                this.$store.commit('changeLoading', false);
            });
            this.clientUploadDocument = id;
            this.modalTitle = this.t('ACTIONS.ATTACH_DOCUMENT')
            this.wichModal = 3;
        },
        async printClientPdf(iten) {
            this.$store.commit('changeLoading', true);
            await Clients.exportPdfClient(iten.id).then(async (response) => {
                if (response.data?.length) {
                    const mergedPdf = await PDFDocument.create();
                    for (const pdfObj of response.data) {
                        if (pdfObj?.basefile){
                            const pdfBase64 = pdfObj.basefile;
                            const pdfBytes = this.base64ToUint8Array(pdfBase64);
                            try {
                                const pdfDoc = await PDFDocument.load(pdfBytes);
                                const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                                copiedPages.forEach((page) => mergedPdf.addPage(page));
                            }catch (err){
                                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf';
                                fileName = fileName?.replaceAll('.pdf', '');
                                fileName = fileName + (pdfObj?.name ? pdfObj?.name : '.pdf');
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                            }
                        }
                    }

                    const mergedPdfFile = await mergedPdf.save();
                    const blob = new Blob([mergedPdfFile], {type: 'application/pdf'});
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf'
                    a.href = url;
                    a.download = fileName;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                console.log(err);
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            })
        },
        base64ToUint8Array(base64) {
            const base64Data = base64.replace(/^data:application\/pdf;base64,/, '');
            const binaryString = window.atob(base64Data);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        },
        chooseFile(e) {
            let filename = ''
            let targetFiles = e.target.files;
            for (let file of targetFiles) {
                filename = filename + file.name + '; '
            }
            this.file = {'fileName': filename, ...targetFiles}
        },
        uploadDocument(file) {
            if (!file) return this.toast.error(this.t('CLIENTS.DOCUMENTS.FILE_EMPTY'));
            this.$store.commit('changeLoading', true);
            let formData = new FormData();
            formData.append('file', file);
            formData.append('context_id', this.clientUploadDocument);
            formData.append('context', 'clientes');
            Drive.uploadDocument(formData).then((resp) => {
                if (resp.data.code) {
                    this.toast.success(resp.data.description);
                } else {
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.CREATED'));
                }
                this.documents.push(resp.data);
                this.$store.commit('changeLoading', false);
            }).catch(err => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            }).finally(() => {
                this.$store.commit('changeLoading', false)
            })
        },

        setFile() {
            this.uploadFile = true;
            this.isAnex = true;
            // document.getElementById('inputFileDocument').click()
        },
        startPermissons() {
            this.hasShowPermission = this.validatePermissions('Client:show');
            this.hasListPermission = this.validatePermissions('Client:list');
            this.hasCreatePermission = this.validatePermissions('Client:create');
            this.hasUpdatePermission = this.validatePermissions('Client:update');
            this.hasDeletePermission = this.validatePermissions('Client:delete');
        },
        exportClient() {
            this.$store.commit('changeLoading', true);
            Clients.exportFile().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'pessoas.xlsx');
                link.click();
                this.$store.commit('changeLoading', false);
            })
        },
        index(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            Clients.index(page, filter, this.perPage).then(resp => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });
        },
        open(client) {
            client.open = !client.open ? true : false;
        },
        importFile(file) {
            if (this.isAnex) {
                this.uploadDocument(file)
                return;
            }
            this.$store.commit('changeLoading', true);
            Clients.importFile(file).then(async () => {
                await this.index();
                this.toast.success(this.t('CLIENTS.CLIENT_IMPORT_WITH_SUCCESSS'));
                this.uploadFile = false;
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.errorMsg(err);
                this.uploadFile = false;
                this.$store.commit('changeLoading', false);
            });
        },
        copyLinkClient() {
            let userId = jwt_decode(localStorage.getItem('access_token')).user_id;
            let link = this.clientPublic + '/cliente-publico/' + localStorage.getItem('companyId') + '/' + userId;

            navigator.clipboard.writeText(link).then(() => {
                this.toast.success("Link de acesso ao cadastro de clientes na área pública foi copiado sucesso");
            });
        },

        getNamesOfRealtor() {
            User.showByCompanyWithoutPaginate(localStorage.getItem('companyId')).then(resp => {
                this.ids = resp.data;
            })
        },

        async setRegisterModal(type) {
            this.wichModal = null;
            if (type === 'juridical') {
                this.isPhisical = false;
                await this.getAttributesValidations('clients_juridical');
            } else {
                this.isPhisical = true;
                await this.getAttributesValidations('clients_phisical');
            }
            this.wichModal = 1;
            this.modalTitle = 'CLIENTS.MODAL_TITLE.ADD';
            this.client = {
                rg: null,
                name: null,
                phones: [],
                email: null,
                taxpayer: null,
                mother_name: null,
                father_name: null,
                sex: null,
                nationality: null,
                description: null,
                created_by: null,
                profession_id: null,
                marital_status_id: null,
                naturalness: null,
                dispatch_date: null,
                marital_status: null,
                dispatching_agency: null
            };
            this.address = {
                cep: null,
                street: null,
                number: null,
                complement: null,
                neighborhood: null
            };
        },

        switchToEdit(id) {
            if (this.hasUpdatePermission) {
                this.toast.success(this.t("GENERAL.MSG.CLIENT_ALREADY_IN_COMPANY"));
                this.wichModal = 1;
                this.showClient(id);
                this.modalTitle = 'CLIENTS.MODAL_TITLE.EDIT';
                this.$store.commit('changeLoading', false)
            }
        },

        async setEditModal(id) {
            this.$store.commit('changeLoading', true);
            await this.showClient(id, true);
            this.modalTitle = 'CLIENTS.MODAL_TITLE.EDIT';
            this.wichModal = 1;
        },

        async showClient(id, edit = false) {
            await Clients.show(id).then(async resp => {
                this.client = resp.data;
                this.client.income = this.client.income ? parseFloat(this.client.income).toFixed(2) : null;
                this.client.income_family = this.client.income_family ? parseFloat(this.client.income_family).toFixed(2) : null;
                if (this.client.dispatch_date) {
                    this.client.dispatch_date = new Date(resp.data.dispatch_date).toISOString().substr(0, 10)
                }
                if (resp.data.address) {
                    this.address = resp.data.address;
                } else {
                    this.address = {
                        cep: null,
                        street: null,
                        number: null,
                        neighborhood: null,
                        complement: null,
                        city_id: null,
                    }
                }
                if (edit) {
                    if (this.client?.taxpayer?.length <= 14) {
                        await this.getAttributesValidations('clients_phisical');
                    } else {
                        await this.getAttributesValidations('clients_juridical');
                    }
                }
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },

        setViewModal(id) {
            this.$store.commit('changeLoading', true);
            this.wichModal = 2;
            this.showClient(id);
            this.modalTitle = 'CLIENTS.MODAL_TITLE.VIEW';
        },

        showAlert(id) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),

                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Clients.delete(id).then(() => {
                        this.index();
                        document.getElementById('closeX').click();
                        this.toast.success(this.t('GENERAL.MSG.DELETED_SUCCESS'));
                        this.$store.commit('changeLoading', false);
                    }).catch(error => {
                        this.toast.error(this.t('GENERAL.MSG.DELETE_NOT_PERFORMED_BECAUSE_DEPENDENCY'), {timeout: false});
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                }
            });
        },

        realtorName(client) {
            let data = this.ids.find(function (dt) {
                return dt.id === client.created_by;
            })
            return data?.text;
        },

        phoneNumber(client) {
            if (client.phones.length > 1) {
                let tel = client.phones;
                switch (client.phones.length) {
                    case 2:
                        return `${tel[0].number}/${tel[1].number}`;
                    case 3:
                        return `${tel[0].number}/${tel[1].number}/${tel[2].number}`;
                }
                return `${tel[0].number} - AVISO: ${this.t('GENERAL.MSG.ACCESS_OTHER_PHONE_NUMBERS')}`
            }
            return client.phones[0]?.number;
        },

        phoneNumberToCell(client) {
            if (client.phones.length > 1) {
                let tel = client.phones;
                switch (client.phones.length) {
                    case 2:
                        return `${tel[0]?.number}/${tel[1]?.number}`;
                }
                return `${tel[0]?.number} - AVISO: ${this.t('GENERAL.MSG.ACCESS_OTHER_PHONE_NUMBERS')}`
            }
            return client.phones[0]?.number;
        },

        search(filter) {
            this.filter = {filtro: filter};
            this.index(this.filter, 1);
        }
    }
}
</script>

<style>
.color-grey {
    color: #727272;
}

.font-seven {
    font-size: 0.7rem !important;
}
</style>
